.App {
  text-align: center;
  background-color: #003057;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  padding-top: 44px;
  padding-bottom: 44px;
}

@media screen and (max-width: 768px) {
  body {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.disable-text-selection {
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE*/
  user-select: none; /* Standard syntax */
}