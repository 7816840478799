
h1 span, h6 span {
    opacity: 0;
    animation: move-text 0.75s forwards;
}

@keyframes move-text {
    0% { bottom: -0.2em; opacity: 1; }
    50% { bottom: 0.2em; }
    100% { bottom: 0; opacity: 1; }
}

.backgroundGif-light {
    background: url('../../assets/images/backgrounds/light_background.gif');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.backgroundGif-dark {
    background: url('../../assets/images/backgrounds/dark_background.gif');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.centertext {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
}