
.navbar-height {
    min-height: 44px;
}

@media screen and (max-width: 768px) {
    body {
      padding-top: 44px;
    }
}

.dropdown-button {
    background-color: transparent;
    border-color: transparent;
}

// expand dropdown menu when cursor hovers
.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; /* remove the gap so it doesn't close */
}
